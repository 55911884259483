import PhaseGroupField from '@asaprint/asap/components/forms/fields/PhaseGroupField.js';
import { PhaseGroupField_PhaseGroupFragment } from '@asaprint/asap/schema.client.types.js';
import { Option } from '@engined/client/components/forms/fields/AutocompleteField.js';
import TextField from '@engined/client/components/forms/fields/TextField.js';
import { FormErrors } from '@engined/client/hooks/useFormResolver.js';
import { Grid } from '@mui/material';
import React from 'react';

export interface FormValues {
  id?: string;
  updatedAt?: string;
  name: string;
  group: Option<PhaseGroupField_PhaseGroupFragment>;
  color: string;
  position?: number;
  calculatorName: string;
}

interface OwnProps {
  showPosition?: boolean;
}

type Props = OwnProps;

const PhaseGroupForm: React.FunctionComponent<Props> = ({ showPosition }) => {
  return (
    <Grid container spacing={2}>
      {showPosition && (
        <Grid item xs={12} md={6}>
          <TextField
            name="position"
            type="number"
            label="Pozícia"
            required
            inputProps={{ min: '0', step: '1' }}
            fullWidth
          />
        </Grid>
      )}
      <Grid item xs={12} md={6}>
        <PhaseGroupField name="group" inputLabel="Skupina fáz" inputRequired />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField name="name" type="text" label="Názov" required maxLength={255} fullWidth />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField name="color" type="color" label="Farba" required fullWidth />
      </Grid>
      <Grid item xs={12} md={showPosition ? 12 : 6}>
        <TextField name="calculatorName" type="text" label="Fáza v  maloformátovom kalkulátore" required fullWidth />
      </Grid>
    </Grid>
  );
};

PhaseGroupForm.displayName = 'PhaseGroupForm';

export default PhaseGroupForm;

export function validate(values: FormValues) {
  const errors: FormErrors<FormValues> = {};

  if (!values.name) {
    errors.name = 'Názov fázy je povinný';
  }

  if (!values.color) {
    errors.color = 'Farba fázy je povinná';
  }

  if (!values.group) {
    errors.group = 'Skupina fáz je povinná';
  }

  if (values.id) {
    if (!values.position && values.position !== 0) {
      errors.position = 'Pozícia je povinná';
    } else if (values.position < 0) {
      errors.position = 'Pozícia je neplatná';
    }
  }

  return errors;
}
