import { PhaseGroupField_Load } from '@asaprint/asap/components/forms/fields/PhaseGroupField.graphql';
import { PhaseGroupField_LoadQuery } from '@asaprint/asap/schema.client.types.js';
import { toOption } from '@asaprint/common/helpers/PhaseGroup.js';
import { Option } from '@engined/client/components/forms/fields/AutocompleteField.js';
import RelationField, { Props as RelationFieldProps } from '@engined/client/components/forms/fields/RelationField.js';
import React from 'react';

interface OwnProps {}

type SpecificRelationFieldProps = RelationFieldProps<Option, any, any, any>;
type Props = OwnProps & Omit<SpecificRelationFieldProps, 'dataToOptions' | 'query' | 'variables'>;

const phaseGroupsToOptions = (data: PhaseGroupField_LoadQuery): Option[] =>
  data?.phaseGroups?.edges.map(({ node }) => toOption(node)) ?? [];

const PhaseGroupField: React.FunctionComponent<Props> = ({ name, ...rest }) => {
  return <RelationField {...rest} query={PhaseGroupField_Load} dataToOptions={phaseGroupsToOptions} name={name} />;
};

PhaseGroupField.displayName = 'PhaseGroupField';

export default React.memo<Props>(PhaseGroupField);
